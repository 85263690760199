import React from 'react';
import Container from 'react-bootstrap/Container';
import Seo from '@/components/SEO';
import Root from '@/layouts/RootPolicy';

import '@/assets/css/termos.scss';

const Termos = () => (
  <>
    <Seo
      title="Termos de uso"
      description="Conheça os termos e condições da Simples Dental. Saiba mais sobre nossas políticas e diretrizes para o uso de nossos serviços."
    />
    <Root>
      <Container>
        <div className="termos-policy">
          <article dir="ltr">
            <h1>TERMOS E CONDIÇÕES DE USO DA PLATAFORMA SIMPLES DENTAL</h1>
            <p>Arquivo atualizado em: 14/01/2025.</p>

            <div className="intro">
              <p>Olá! </p>
              <p>
                Os termos e condições de uso abaixo disciplinarão as regras para sua experiência na
                plataforma da Simples Dental e seus produtos, por isso é importante que você leia
                todo o conteúdo antes de iniciar a usar os nossos produtos.
              </p>
              <p>Obrigada por escolher a Simples Dental.</p>
            </div>

            <h2>CLÁUSULA 1ª – DAS DEFINIÇÕES</h2>
            <p>
              Simples Dental: SIMPLES DENTAL SOFTWARE SA, pessoa jurídica de direito privado,
              inscrita no CNPJ sob nº 17.607.744/0001-71, com sede na Rua Senador Carlos Gomes de
              Oliveira, 800 - Galpão 04 - Distrito Industrial, São José - SC, 88104-785.
            </p>
            <p>
              <strong>1.1 Usuário Administrador:</strong> A pessoa física maior de 18 anos ou a
              pessoa jurídica contratante, neste ato, portador(a) dos documentos informados à
              Simples Dental, devidamente cadastrado no banco de dados da Simples Dental, por meio
              do login e senha.
            </p>
            <p>
              <strong>1.2 Usuário Secundário:</strong> São aqueles que foram ou serão cadastrados
              pelo Usuário Administrador e que utilizem a Plataforma para execução dos serviços
              devidamente cadastrado no banco de dados da Simples Dental, por meio do login e senha.
            </p>
            <p>
              <strong>1.3 Usuário:</strong> Usuário Administrador e Usuário Secundário.
            </p>
            <p>
              <strong>1.4 Plataforma:</strong> Software desenvolvido pela Simples Dental para gestão
              de clínicas e consultórios odontológicos disponibilizada em
              https://www.simplesdental.com. Essa definição inclui software e outras formas de
              propriedade intelectual da Simples Dental.
            </p>
            <p>
              <strong>1.5 Aceite:</strong> Será dado pelo Usuário, quando este se cadastrar na
              Plataforma ou iniciar, de qualquer maneira, o uso da Plataforma, o que ocorrer
              primeiro, o aceite e concordância com o integral teor deste Termo de Uso e da Política
              de Privacidade da Simples Dental, sendo aplicáveis desde então e vincularão o Usuário
              para todos os fins e efeitos de direito.
            </p>
            <p>
              <strong>1.6 Cliente da Dental Cremer:</strong> Usuário, que tenha realizado ao menos
              uma compra, de qualquer valor, na Dental Cremer Produtos Odontológicos nos últimos 3
              meses e não esteja com nenhuma parcela em atraso.
            </p>
            <p>
              <strong>1.7 Cliente da Dental Speed:</strong> Usuário, que tenha realizado ao menos
              uma compra, de qualquer valor, na Quantity Serviços e Comércio de Produtos para a
              Saúde S.A nos últimos 3 meses e não esteja com nenhuma parcela em atraso.
            </p>
            <p>
              <strong>1.8 Cadastro:</strong> Significa a inclusão dos dados do Usuário, solicitados
              pela Simples Dental, na Plataforma para sua utilização pelo Usuário conforme estes
              Termos de Uso.
            </p>
            <p>
              <strong>1.9 Conta:</strong> A conta será criada a partir do preenchimento de Cadastro
              na Plataforma utilizando apenas informações verdadeiras, e seleção do tipo de plano
              que está disponível para contratação do Usuário.
            </p>
            <p>
              <strong>1.10 Pacientes:</strong> significam os pacientes da clínica ou consultório
              odontológico do Usuário Administrador.
            </p>
            <p>
              <strong>1.11 Dados de Pacientes:</strong> Dados pessoais dos Pacientes inseridos pelo
              Usuário Administrador e Usuário Secundário na área Cadastro de Pacientes da
              Plataforma.
            </p>
            <p>
              <strong>1.12 Período de teste:</strong> O período de teste da plataforma Simples
              Dental é de 7 dias consecutivos, após o período de teste usuário poderá optar pela
              contratação ou não da ferramenta Simples Dental.
            </p>
            <p>
              <strong>1.13 Política de Privacidade:</strong> significa a Política de Privacidade
              para a utilização pela Plataforma, disponível através do link Política de privacidade.
              (inserir hyperlink).
            </p>
            <p>
              <strong>1.14 Serviços Adicionais:</strong> significam os serviços que poderão ser
              contratados separadamente pelo Usuário mediante o pagamento de quantia adicional. Role
              até o final página, e encontre todos os detalhes em uma tabela.
            </p>
            <p>
              <strong>1.15 Site do Dentista:</strong> Ferramenta que permite a criação de página
              online simples e única em nome do Usuário com o domínio meudoutor.com, disponibilizado
              pela SIMPLES DENTAL. O Usuário pode personalizar a página e o nome do domínio, por
              exemplo, www.clínicasorriso.meudoutor.com. incluindo na página seu nome ou logo,
              endereço e especialidades, entre quaisquer outras informações que tenham relação com
              os serviços por ele prestados. O Usuário é o único e exclusivo responsável por tal
              página, ou seja, a SIMPLES DENTAL apenas auxiliar o Usuário com o domínio para
              formatação. A responsabilidade pelo conteúdo inserido no Site do Dentista é somente do
              Usuário, inclusive com relação a inclusão de políticas e regras, não se limitando a
              eventual política de privacidade e proteção de dados pessoais. A SIMPLES DENTAL apenas
              e tão somente disponibilizará o domínio, para que o Usuário possa personalizar a sua
              página própria como quiser. O domínio criado pela ferramenta Site do Dentista, é de
              propriedade da SIMPLES DENTAL, sendo disponibilizado ao Usuário, mediante login e
              senha, os quais são pessoais e intransferíveis. Em qualquer hipótese de término da
              contratação da plataforma SIMPLES DENTAL, seja a pedido do Usuário ou por violações a
              estes Termos, o Usuário não terá direito em manter o domínio criado pela SIMPLES
              DENTAL. Todo e qualquer domínio disponibilizado na ferramenta “Site do Dentista” é de
              propriedade exclusiva da SIMPLES DENTAL.
            </p>
            <p>
              <strong>1.16 Lead:</strong> Contato que demonstrou interesse ao se cadastrar no site
              criado através da ferramenta Site do dentista e clicou no botão “chamar no Whatsapp”.
            </p>
            <p>
              <strong>1.17 Log de acesso:</strong> Registros de acesso a aplicações de internet que
              compõem o conjunto de informações referentes à data e hora de uso de uma determinada
              aplicação de internet a partir de um determinado endereço IP.
            </p>

            <h2>CLÁUSULA 2ª - DO OBJETO</h2>
            <p>
              <strong>2.1.</strong> Estes Termos de Uso regulam os termos e condições aplicáveis à
              utilização da Plataforma, pelo Usuário, da SIMPLES DENTAL, que é uma ferramenta que
              auxilia a administração de sua clínica ou consultório odontológico, bem como a
              contratação pelo Usuário de eventuais Serviços Adicionais.
            </p>
            <p>
              <strong>2.2.</strong> O Usuário reconhece que a Plataforma é de exclusiva propriedade
              e titularidade da SIMPLES DENTAL, inclusive, mas sem limitação, todos e quaisquer
              direitos de propriedade intelectual ou proprietários relacionados a este, sendo que o
              direito de acesso e uso do mesmo concedido pela SIMPLES DENTAL ao Usuário é restrito
              ao Usuário, não podendo ser compartilhado ou transmitido a terceiros. Qualquer uso da
              Plataforma SIMPLES DENTAL não expressamente previsto nestes Termos de Uso é proibido e
              sujeita o Usuário às penalidades aqui estabelecidas, sem prejuízo das perdas e danos
              cabíveis e consequências legais aplicáveis.
            </p>

            <h2>
              CLÁUSULA 3ª – DA VINCULAÇÃO E ACEITE DOS TERMOS DE USO E DA POLÍTICA DE PRIVACIDADE
            </h2>
            <p>
              <strong>3.1.</strong> O Usuário concorda que os termos e condições destes Termos de
              Uso e da Política de Privacidade (“Termos”) serão aplicáveis e vincularão os Usuários
              a partir do momento do Aceite.
            </p>
            <p>
              O Usuário declara que: (i) está ciente de que estes Termos de Uso e a Política de
              Privacidade correspondem a um contrato vinculante ao Usuário, (ii) que possui
              capacidade civil e jurídica para executar o Aceite desses, e que o Aceite é
              manifestado por uma pessoa com poderes para tanto, sendo responsabilidade exclusiva do
              Usuário aferir e se assegurar que a pessoa que forneceu Aceite aos Termos de Uso e à
              Política de Privacidade tem poderes para executar tal ato; (iii) está de acordo com a
              forma e método de assinatura e Aceite a estes Termos de Uso e à Política de
              Privacidade, sendo está uma forma válida e eficaz para vincular o Usuário aos seus
              termos e obrigações.
            </p>
            <p>
              <strong>3.1.1.</strong> Caso a SIMPLES DENTAL verifique, a seu exclusivo critério, que
              o Usuário (i) concorre, direta ou indiretamente, com a SIMPLES DENTAL e/ou com a
              Plataforma; (ii) pode colocar em risco a utilização da Plataforma por outros Usuários;
              (iii) viole as condições desses Termos de Uso ou da Política de Privacidade; (iv)
              adote qualquer outra conduta que seja incompatível com o uso regular da Plataforma,
              poderá a Simples Dental, a seu exclusivo critério, bloquear o acesso do Usuário à
              Plataforma e consequentemente rescindir estes Termos de Uso, sem que sejam devidas
              quaisquer compensações pela SIMPLES DENTAL.
            </p>
            <p>
              <strong>3.1.2.</strong> O Usuário reconhece que as operações de Aceite destes Termos
              de Uso ou da Política de Privacidade serão registradas nos bancos de dados da SIMPLES
              DENTAL, juntamente com a data e hora em que o Aceite foi manifestado pelo Usuário,
              podendo tal informação ser utilizada como prova do Aceite do Usuário,
              independentemente de outra formalidade.
            </p>
            <p>
              <strong>3.2.</strong> O Usuário está ciente que qualquer uso da Plataforma é sujeito a
              estes Termos de Uso e à Política de Privacidade, sendo que o Usuário deverá ler e
              Aceitar ambos os documentos antes de iniciar o uso de sua conta de acesso à
              Plataforma.
            </p>
            <p>
              <strong>3.2.1.</strong> SERÁ OBRIGAÇÃO DO USUÁRIO, SEMPRE QUE CABÍVEL, GARANTIR QUE
              TODOS OS SEUS EMPREGADOS, PRESTADORES DE SERVIÇOS E PACIENTES ESTEJAM CIENTES E DE
              ACORDO COM ESTES TERMOS DE USO E A POLÍTICA DE PRIVACIDADE.
            </p>
            <p>
              <strong>3.3.</strong> O USUÁRIO É INTEIRAMENTE E EXCLUSIVAMENTE RESPONSÁVEL PELAS
              INFORMAÇÕES PRESTADAS NO CADASTRO.
            </p>

            <h2>CLÁUSULA 4ª - DA UTILIZAÇÃO DA PLATAFORMA SIMPLES DENTAL</h2>
            <p>
              <strong>4.1.</strong> O Usuário Administrador deverá criar a sua Conta por meio do
              preenchimento de Cadastro completo na Plataforma, respondendo pela veracidade das
              informações, e deverá selecionar um dos tipos de plano que esteja disponível para
              contratação.
            </p>
            <p>
              <strong>4.1.1.</strong> O Usuários Administrador poderá cadastrar Usuários Secundários
              da Plataforma, mediante o preenchimento de todos os dados solicitados, respondendo
              pela veracidade destes. Após o cadastro, cada Usuário Secundário receberá um login e
              senha para utilizar a Plataforma.
            </p>
            <p>
              <strong>4.1.2.</strong> O Usuário é o único responsável pelo Login e pela sua senha
              cadastrados e por qualquer atividade realizada através da utilização na Plataforma.
            </p>
            <p>
              <strong>4.2.</strong> A SENHA CADASTRADA PELO USUÁRIO É PESSOAL E INTRANSFERÍVEL,
              CABENDO AO USUÁRIO MANTER A CONFIDENCIALIDADE E SEGURANÇA DAS SUAS CREDENCIAIS AO
              ACESSAR A PLATAFORMA. O USUÁRIO NÃO DEVERÁ DIVULGAR, OU DE QUALQUER FORMA PARTILHAR,
              ESTAS CREDENCIAIS COM TERCEIROS.
            </p>
            <p>
              <strong>4.3.</strong> O Usuário deverá informar todos os dados e informações
              solicitados pela SIMPLES DENTAL para utilização da Plataforma SIMPLES DENTAL,
              observada a Política de Privacidade, incluindo documentação de suporte das
              informações, respondendo pela veracidade e exatidão.
            </p>
            <p>
              <strong>4.3.1.</strong> Para cadastros de pessoas físicas, quando verificado pela
              SIMPLES DENTAL que o Usuário não é maior de 18 anos, a SIMPLES DENTAL terá o direito
              de excluir o acesso e o Cadastro com os respectivos dados coletados unilateralmente,
              uma vez que a Plataforma não deverá ser usada por menores de 18 anos.
            </p>
            <p>
              <strong>4.3.2.</strong> Para os Cadastros de pessoas jurídicas, deverá ser
              disponibilizado ainda CNPJ ativo junto à Receita Federal do Brasil para que o Cadastro
              seja válido. O Usuário se obriga a manter todas as licenças e autorizações de
              funcionamento ativas e em bom estado durante toda a utilização da Plataforma sob pena
              de cancelamento, nos termos da Cláusula 10 deste Termo de Uso.
            </p>
            <p>
              <strong>4.4.</strong> O USUÁRIO fica ciente de que a SIMPLES DENTAL, em decorrência
              dos serviços prestados por meio da Plataforma, não faz parte da relação de entidades
              com atividades no âmbito da odontologia, incluindo clínicas, policlínicas,
              cooperativas, planos de assistência à saúde, convênios de qualquer forma,
              credenciamento, administradoras, intermediadoras, seguradoras de saúde, ou quaisquer
              outras entidades. Como resultado, a SIMPLES DENTAL não se sujeita às regras do Código
              de Ética Odontológica (Resolução CFO n° 118/2012), porque que não exerce a
              odontologia, sequer de forma indireta. A Plataforma é apenas um recurso material
              contratado pelo USUÁRIO, que, por sua vez, prestará os serviços de odontologia.{' '}
            </p>
            <p>
              <strong>4.5.</strong> Durante a utilização da Plataforma a SIMPLES DENTAL somente
              registrará os Logs de Acesso segundo a Lei do Marco Civil da Internet (Lei nº 12.965,
              de 23 de abril de 2014) em seu artº 7, inciso VI, ou seja, não será registrado nenhum
              outro log.
            </p>
            <p>
              <strong>4.6.</strong> Durante o uso da Plataforma o Usuário poderá gratuitamente optar
              por importar dados de seus pacientes, armazenados em outros softwares ou planilhas na
              Plataforma e para isso a Simples Dental irá realizar uma análise técnica dos dados
              recebidos unicamente para verificar a viabilidade da importação dos dados recebidos.
              Nesse sentido a Simples Dental declara que não se responsabilizará pela veracidade dos
              dados recebidos. O Usuário deverá ter autorização dos titulares dos dados para
              realizar a importação no Software da Simples Dental.
            </p>
            <p>
              <strong>4.6.1.</strong> Os dados que poderão ser importados pela Simples Dental
              incluem:
            </p>
            <p>
              (a) Dados cadastrais: Dados referentes ao cadastro da paciente e que estão presentes
              na ficha do paciente, na aba sobre.
            </p>
            <p>
              (b) Evoluções: Dados referentes apenas à tratamentos finalizados, serão incluídos nas
              evoluções do paciente, presente na aba tratamentos da ficha do paciente.
            </p>
            <p>
              (c) Financeiro: Dados referentes a recebimentos de pacientes em aberto ou recebidos,
              serão incluídos no fluxo financeiro da Simples Dental, nomeados como "Receita
              Importada".
            </p>
            <p>
              <strong>4.7</strong> O usuário será considerado inativo quando (i) não efetuar o
              pagamento da mensalidade por 6 (seis) meses consecutivos ou alternados, (ii) caso não
              faça mais jus ao plano gratuito, deixar de migrar para um dos outros planos, ou (iii)
              no período de testes não efetuar a contratação efetiva do sistema em até 15 (quinze)
              dias do início do período de testes. Nestes casos, os dados e informações
              eventualmente inseridas pelo Usuário na Plataforma poderão ser excluídos, observado o
              disposto na Cláusula 8.1.2.
            </p>
            <p>
              <strong>4.8.</strong> Para os fins da Lei Geral de Proteção de Dados (Lei n°
              13.709/2018) e nos termos da Política de Privacidade da Simples Dental, o Usuário
              Administrador e o Usuário Secundário serão considerados co-controladores dos dados
              pessoais dos Pacientes cadastrados na Plataforma. Dessa forma, na condição de
              operadora de dados, a Simples Dental poderá atender a solicitações de acesso aos dados
              pessoais dos pacientes tanto do Usuário Administrador, quanto do Usuário Secundário,
              desde que atendidas as condições explicitadas no item 4.9. abaixo.
            </p>
            <p>
              <strong>4.9.</strong> A responsabilidade pela indicação e cadastro de Pacientes e
              procedimentos na Plataforma é do Usuário.
            </p>
            <p>
              <strong>4.9.1.</strong> Caso a indicação e cadastro de Pacientes e procedimentos se
              der pelo Usuários Secundário, na qualidade de profissional dentista, durante o
              exercício de suas atividades profissionais, a Simples Dental não se responsabilizará
              pela migração dos dados de Pacientes do Usuário Secundário para outros sistemas, a não
              ser que os Pacientes estejam devidamente vinculados ao Usuário Secundário em questão
              no Simples Dental.
            </p>
            <p>
              <strong>4.9.2.</strong> Em caso de dúvidas a respeito da titularidade dos dados
              pessoais dos Pacientes, nos casos em que o Usuário Secundário não foi vinculado ao
              Paciente, a Simples Dental apenas efetivará a migração dos referidos dados mediante
              acordo formal e por escrito entre o Usuário Administrador e o Usuário Secundário.
            </p>

            <h2>CLÁUSULA 5ª - DO PAGAMENTO</h2>
            <p>
              <strong>5.1.</strong> Planos:
            </p>
            <p>
              <strong>5.1.1.</strong> Plano Freemium: Isento, de maneira condicionada ao atendimento
              dos requisitos vigentes.
            </p>
            <p>
              <strong>5.1.2.</strong> Plano Pago: pagamento dos valores relativos ao Plano Pago
              utilizado pelo Usuário ocorrerá por meio de (i) boleto da SIMPLES DENTAL ou (ii)
              cartão de crédito do Usuário. O meio de pagamento é selecionado no momento da
              contratação do Plano Pago e pode ser alterado pelo Usuário a qualquer momento junto à
              Plataforma.
            </p>
            <p>
              <strong>5.1.3.</strong> Plano Redes e Franquias: valores sob consulta, sendo que os
              pagamentos poderão ser realizados por meio de (i) boleto da SIMPLES DENTAL ou (ii)
              cartão de crédito do Usuário. O meio de pagamento é escolhido no momento da
              contratação e pode ser alterado pelo Usuário a qualquer momento junto à Plataforma.
              Diferentemente dos demais planos, a contratação deste Plano Redes e Franquias é sob
              consulta e poderá ser formalizado mediante simples aceite por e-mail ou mensagem ou
              início da utilização da Plataforma, o que ocorrer primeiro. O uso da Plataforma será
              considerado, para todos os efeitos, como plena aceitação destes Termos e da Política
              de Privacidade da Plataforma.
            </p>
            <p>
              <strong>5.1.4.</strong> Serviços Adicionais: deverão efetuar o pagamento dos valores
              relativos aos Serviços Adicionais contratados junto à Plataforma por meio de (i)
              boleto da SIMPLES DENTAL ou (ii) cartão de crédito do Usuário. Para os usuários do
              Plano Freemium será permitida exclusivamente a compra de pacotes de SMS.
            </p>
            <p>
              <strong>5.2.</strong> A qualquer momento um Usuário do Plano Freemium poderá optar por
              migrar para um Plano Pago, devendo para tanto apenas informar esta opção junto à
              Plataforma e pagar o valor respectivo para uso do Plano Pago escolhido.
            </p>
            <p>
              <strong>5.3.</strong> Os valores dos planos contratados poderão ser atualizados,
              anualmente, de acordo com a tabela de planos e preços vigente no momento do reajuste,
              caso isto ocorra o Usuário será informado com no mínimo 7 dias de antecedência da
              renovação em que ocorrerá o reajuste de preços.
            </p>
            <p>
              <strong>5.4.</strong> A Simples Dental não se responsabiliza pela não realização de
              transação de pagamento via cartão de crédito. As recusas, assim como suas
              justificativas, são informações prestadas por instituições financeiras ligadas ao
              cartão do Usuário, não tendo a Simples Dental qualquer gerência sobre as mesmas.
            </p>

            <h2>CLÁUSULA 6ª - DA CONTRATAÇÃO DE SERVIÇOS ADICIONAIS</h2>
            <p>
              <strong>6.1.</strong> O Usuário poderá optar pela contratação de Serviços Adicionais
              (Plano Freemium exclusivamente SMS) disponíveis no plano escolhido, que dependam de
              pagamento de quantias adicionais, conforme anunciado de tempos em tempos.
            </p>
            <p>
              <strong>6.2.</strong> As condições para a utilização dos Serviços Adicionais, bem como
              a forma de pagamento dos respectivos valores serão determinadas no momento de
              contratação. Os termos de uso para cada Serviço Adicional firmado pelo Usuário serão
              considerados complementares e anexos aos Termos de Uso e à Política de Privacidade.
            </p>
            <p>
              <strong>6.2.1.</strong> Os Serviços Adicionais eventualmente contratados na forma do
              item 6.1 acima poderão também ser prestados por parceiros terceiros da Simples Dental,
              sendo que neste caso os termos de uso do respectivo Serviço Adicional serão
              disponibilizados pela Simples Dental através de link para a página do parceiro.
            </p>

            <h2>CLÁUSULA 7ª - DA LICENÇA</h2>
            <p>
              <strong>7.1.</strong> Sujeito aos termos e condições destes Termos de Uso, a SIMPLES
              DENTAL concede ao Usuário uma licença, não exclusiva, limitada e intransferível para
              uso exclusivo pelo Usuário da Plataforma em um ou mais computadores e/ou dispositivos
              móveis de titularidade ou posse legítima do Usuário como ferramenta auxiliar de
              gestão, sujeito aos Termos de Uso.
            </p>
            <p>
              <strong>7.2.</strong> O Usuário concorda que a licença concedida na Cláusula 7.1
              acima, não contempla ou autoriza o Usuário a usar a Plataforma para os seguintes
              propósitos:
            </p>
            <p>
              (i) fazer, direta ou indiretamente, engenharia reversa, reproduzir, traduzir,
              decompilar, desmontar, realizar atos que visem extrair código fonte, atualizar, rever
              ou melhorar o software ou qualquer parte deste ou envolver terceiros para quaisquer
              desses objetivos.
            </p>
            <p>
              (ii) explorar a Plataforma e os Serviços Adicionais, no todo ou em parte, para prestar
              ou intermediar os Serviços para qualquer outra pessoa ou entidade que não seja o
              Usuário, com ou sem fim comercial.
            </p>
            <p>
              (iii) usar qualquer software de terceiros que interfira ou interaja com a Plataforma,
              a fim de otimizar o desempenho deles ou para coletar dados e informações de Usuários e
              Pacientes, sendo certo, no entanto, que a Plataforma poderá utilizar interfaces de
              terceiros, sempre que cabível e a seu exclusivo critério.
            </p>
            <p>
              (iv) usar a Plataforma ou qualquer parte dessa dela para criar novos aplicativos,
              módulos, produtos ou serviços.
            </p>
            <p>
              (vi) viabilizar, criar ou manter qualquer conexão não autorizada com a Plataforma,
              incluindo, mas sem limitação, qualquer conexão que utilize programas ou ferramentas
              não autorizadas pela SIMPLES DENTAL.
            </p>
            <p>
              (vii) vender (para ganho, lucro ou qualquer outro fim), locar, emprestar, alugar,
              penhorar, usar como garantia, onerar, hipotecar, distribuir, ceder, transferir,
              exportar, melhorar, alterar, editar, criar trabalhos derivados ou conceder uma
              sublicença de qualquer elemento da Plataforma ou de qualquer documentação relacionada.
            </p>
            <p>
              (viii) usar ou permitir o uso, direta ou indiretamente, da propriedade intelectual da
              Plataforma, bem como da Plataforma em si ou qualquer documentação de suporte para
              finalidade diversa daquelas autorizadas pela SIMPLES DENTAL.
            </p>
            <p>
              (ix) invadir ou tentar invadir a privacidade de outros Usuários, buscando acesso à
              senha, dados privados e Dados de Pacientes, bem como modificar arquivos, copiar, ou
              assumir, sem autorização, a identidade de outro assinante, ou desenvolver programas de
              acesso não autorizado a computadores, arquivos, programas e dados residentes na rede,
              bem como fornecer Dados de Pacientes ou dados cadastrais falsos.
            </p>
            <p>
              <strong>7.3.</strong> A SIMPLES DENTAL declara que é proprietária da Plataforma
              SIMPLES DENTAL objeto destes Termos de Uso, e que está autorizada a licenciar seu uso,
              bem como, que a licença aqui pactuada não infringe qualquer patente, direito autoral,
              segredo industrial ou quaisquer outros direitos de terceiros.
            </p>

            <h2>CLÁUSULA 8ª – DO BLOQUEIO DA CONTA</h2>
            <p>
              <strong>8.1.</strong> A SIMPLES DENTAL poderá, a seu exclusivo critério, sem prévia
              notificação ao Usuário, bloquear a Conta caso o Usuário (i) atue da forma prevista nas
              Cláusulas 3.1.1., 4.7., 5.1. e 7.2.; (ii) deixe de efetuar o pagamento previsto na
              Cláusula 5.2 ou de contratar um outro plano, caso não atenda aos requisitos para
              manutenção do plano Freemium; ou (iii) infrinja no todo ou em parte, direta ou
              indiretamente, os Termos de Uso, conforme determinação da SIMPLES DENTAL.
            </p>
            <p>
              <strong>8.1.1.</strong> O Usuário poderá solicitar o desbloqueio de sua Conta por meio
              do e-mail contato@simplesdental.com. A SIMPLES DENTAL poderá, a seu exclusivo critério
              e levando em consideração as informações enviadas pelo Usuário desbloquear a Conta.
            </p>
            <p>
              <strong>8.1.2.</strong> No caso de bloqueio da Conta a SIMPLES DENTAL manterá todos os
              dados e informações inseridas pelo Usuário na Plataforma, incluindo os Dados de
              Pacientes e atendimentos realizados, por um período de 30 (trinta) meses contados da
              data de bloqueio da Conta. Durante este período, o Usuário poderá solicitar
              cópia/backup de tais dados e informações. Após este período, os dados e informações
              poderão ser eliminados e excluídos pela SIMPLES DENTAL, sem a manutenção de qualquer
              backup. Caberá, assim, ao Usuário a única e exclusiva responsabilidade por solicitar
              cópia/backup dos dados e informações no prazo indicado nesta Cláusula. Não obstante, o
              Usuário poderá, também, solicitar a exclusão de dados pessoais do Usuário e de seus
              Pacientes, a qualquer momento, por meio do e-mail contato@simplesdental.com ou através
              do preenchimento do formulário de atendimento ao direito do titular, disponível no
              rodapé da página principal do site.
            </p>

            <h2>CLÁUSULA 9ª – DO PRAZO</h2>
            <p>
              <strong>9.1.</strong> Os presentes Termos de Uso será válido por prazo indeterminado e
              será aplicável para prestação de todos os serviços contratados com o uso da Plataforma
              SIMPLES DENTAL.
            </p>

            <h2>CLÁUSULA 10ª – DO CANCELAMENTO DA LICENÇA DE USO DO USUÁRIO</h2>
            <p>
              <strong>10.1.</strong> O Usuário poderá cancelar sua assinatura de uso da Plataforma
              SIMPLES DENTAL a qualquer momento, sendo certo que no caso de Plano(s) Pago(s) esta
              permanecerá vigente e acessível ao Usuário pelo período contratado quando da seleção e
              pagamento do(s) Plano(s) Pago(s) respectivo. Em nenhuma hipótese haverá ressarcimento
              pela SIMPLES DENTAL de valores pagos pelo Usuário em virtude de cancelamento de
              assinatura previamente ao término do prazo contratado para uso da Plataforma SIMPLES
              DENTAL, independentemente do motivo do cancelamento.
            </p>
            <p>
              <strong>10.2.</strong> A SIMPLES DENTAL poderá a seu critério e independentemente de
              notificação prévia cancelar a assinatura do Usuário de quaisquer Planos caso (i)
              verifique nova ocorrência de quaisquer das violações dispostas na Cláusula 8.1 acima,
              outra regra dos termos de uso ou de qualquer contrato ou acordo com a SIMPLES DENTAL;
              (ii) vislumbre, a critério exclusivo da SIMPLES DENTAL, qualquer falsidade,
              incorreção, incompatibilidade, invalidade ou inatividade/baixa junto aos órgãos
              públicos emissores, dos dados fornecidos pelo Usuário no Cadastro; e/ou (iii) quando
              se tratar de Usuário Inativo.
            </p>
            <p>
              <strong>10.3.</strong> A SIMPLES DENTAL pode, adicionalmente às hipóteses do item
              10.2, cancelar a assinatura do Plano Freemium do Usuário, caso verificado
              descumprimento das condições necessárias para manutenção do Plano Freemium, quais
              sejam realizar na Dental Cremer ou na Dental Speed compras trimestrais no valor mínimo
              de R$ 3.000,00 vinculadas ao CPF ou CNPJ registrado inicialmente (sem possibilidade de
              alteração do CPF ou CNPJ), mantendo-se integralmente adimplente perante Dental Cremer
              e Dental Speed, sem qualquer pagamento vencido e não quitado; inatividade da conta por
              mais de seis meses; ou fornecimento de informações falsas, inexatas, inconsistentes ou
              inválidas no cadastro, conforme avaliação da SIMPLES DENTAL.
            </p>

            <h2>CLÁUSULA 11ª – DA INDENIZAÇÃO</h2>
            <p>
              <strong>11.1.</strong> O Usuário concorda em defender, indenizar e manter indene a
              SIMPLES DENTAL e suas afiliadas, diretores, empregados e agentes, integralmente de e
              contra quaisquer encargos, ações ou demandas, incluindo, mas não limitado a,
              honorários advocatícios razoáveis, resultantes: (i) da eventual utilização indevida da
              Plataforma e Serviços Adicionais oferecidos pela SIMPLES DENTAL; (ii) reclamação de
              Pacientes sobre a utilização de Dados de Pacientes; (iii) da violação das condições
              ora pactuadas, ou ainda (iv) da inobservância das leis e regulamentos aplicáveis.
            </p>
            <p>
              <strong>11.1.1.</strong> O Usuário é o único e exclusivo responsável pelo conteúdo das
              comunicações enviadas aos Pacientes por meio da Plataforma SIMPLES DENTAL,
              obrigando-se a garantir que os conteúdos trafegados não violarão e nem causarão
              exposição indevida ou constrangimento dos destinatários, respondendo ainda por seu
              conteúdo em juízo ou fora dele, e eximindo a SIMPLES DENTAL em caráter incondicional
              de quaisquer responsabilidades por quaisquer reclamações de terceiros, se obrigando
              ainda a indenizar a SIMPLES DENTAL nos termos desta Clausula 11.
            </p>
            <p>
              <strong>11.2.</strong> Em nenhum caso a SIMPLES DENTAL será responsável por danos ou
              qualquer prejuízo direto ou indireto incorrido pelo Usuário ou qualquer outro terceiro
              decorrente da SIMPLES DENTAL, incluindo, sem limitação, prejuízos por perda de lucro,
              corrupção ou perda de dados, falha de transmissão ou recepção de dados, não
              continuidade do negócio ou qualquer outro prejuízo ou perda comercial.
            </p>
            <p>
              <strong>11.3.</strong> Sem prejuízo ao disposto acima, na eventualidade de a SIMPLES
              DENTAL ser compelida, por decisão judicial transitada em julgado, a indenizar ou
              ressarcir o Usuário por danos incorridos por este, o valor devido ao Usuário será
              limitado à totalidade da quantia efetivamente recebida pela SIMPLES DENTAL com a
              prestação dos Serviços que gerou o dano.
            </p>

            <h2>CLÁUSULA 12ª – DA LIMITAÇÃO DE RESPONSABILIDADE</h2>
            <p>
              <strong>12.1.</strong> Sem limitar outras disposições destes Termos de Uso, a SIMPLES
              DENTAL não responderá, em nenhuma hipótese, ainda que em caráter solidário ou
              subsidiário:
            </p>
            <p>
              (a) Pela autenticidade, validade e precisão dos dados fornecidos pelo Usuário e/ou
              informados pelos Pacientes, conforme previsto na Cláusula 4.4 acima, competindo ao
              Usuário verificar, por conta própria, se tais informações são verdadeiras e completas
              antes de tomar qualquer decisão baseada nelas.
            </p>
            <p>
              (b) Pela qualidade, precisão, pontualidade, entrega ou falha na obtenção de
              informações financeiras junto aos sites e/ou meios virtuais das instituições
              financeiras, ou provenientes de serviços prestados por instituições financeiras.
            </p>
            <p>
              (c) Pelos serviços ou produtos oferecidos na SIMPLES DENTAL por anunciantes ou
              quaisquer terceiros, inclusive no que diz respeito a sua disponibilidade, qualidade,
              quantidade, características essenciais, ofertas, preços, formas de pagamento ou
              quaisquer outros elementos a esses referentes.
            </p>
            <p>
              (d) Por eventuais prejuízos sofridos pelo Usuário em razão da tomada de decisões com
              base nas informações disponibilizadas na SIMPLES DENTAL.
            </p>
            <p>
              (e) Pelo envio indevido de qualquer comunicação, incluindo sem limitar e-mail e/ou SMS
              para números ou e-mails cadastrados incorretamente.
            </p>
            <p>(f) Pelo uso indevido e não autorizado das Credenciais do Usuário na Plataforma.</p>
            <p>
              <strong>12.2.</strong> Sem prejuízo a outras disposições destes Termos de Uso, a
              SIMPLES DENTAL não garante que: (a) as funções contidas na SIMPLES DENTAL atendem às
              necessidades do Usuário; (b) a operação da SIMPLES DENTAL será ininterrupta ou livre
              de erros, (c) qualquer funcionalidade da SIMPLES DENTAL permanecerá disponível; (d) os
              defeitos na SIMPLES DENTAL serão corrigidos ou que a SIMPLES DENTAL será compatível ou
              funcionará com qualquer outro software, aplicações ou serviços de terceiros.
            </p>
            <p>
              <strong>12.3.</strong> Sem prejuízo a outras disposições destes Termos de Uso, a
              Simples Dental disponibiliza a minuta de contrato de prestação de serviços
              odontológicos como parte integrante das funcionalidades dos planos Vendas e Marketing.
              O uso da minuta é opcional e totalmente editável para que o dentista possa usá-la da
              forma que melhor se encaixe na sua realidade. A disponibilização da minuta não se
              configura em nenhuma hipótese como uma assessoria jurídica em qualquer circunstância
              assim como a Simples Dental não se responsabiliza por qualquer edição realizada no
              texto ou dados nele incluídos. Salientamos também que a emissão de um contrato não
              evita que um futuro processo jurídico venha a ser enfrentado pela clínica.
              Recomendamos que o preenchimento da minuta seja feito com auxílio de um profissional
              habilitado da área jurídica da clínica.
            </p>
            <p>
              <strong>12.4.</strong> Sem prejuízo a outras disposições destes Termos de Uso, a
              Simples Dental não se responsabiliza pelas informações incluídas pelo Usuário no
              módulo de gestão financeira e nem realiza atualizações as taxas de cartões de crédito
              ou débito praticadas no mercado. A Simples Dental disponibiliza uma configuração para
              que o Usuário inclua as informações vigentes nas operadoras de cartão com as quais
              trabalha em sua clínica ou consultório, mas essa configuração não se caracteriza como
              assessoria financeira e a Simples Dental indica que os Usuários façam periodicamente a
              conciliação para garantir que todas as transações ocorreram conforme previsto entre
              operadora de cartão e clínica.
            </p>
            <p>
              <strong>12.5.</strong> Sem prejuízo a outras disposições destes Termos de Uso, a
              SIMPLES DENTAL disponibiliza a funcionalidade de análise de crédito através da qual a
              clínica conseguirá realizar a consulta do score de crédito de pacientes ou
              responsáveis pelo pagamento. A utilização da análise de crédito é facultativa, sendo
              de responsabilidade da clínica informar a pessoa cujo CPF foi consultado acerca da
              realização da referida consulta, tanto no caso em que a pessoa consultada seja
              paciente, quanto quando a consulta for do CPF da pessoa responsável pelo pagamento do
              tratamento. Tal informação é essencial, visto que a pessoa que terá o CPF consultado
              poderá ter o seu score de crédito temporariamente impactado. A SIMPLES DENTAL não
              assume qualquer responsabilidade por decisões tomadas pela clínica e a análise de
              crédito não se configura como serviço de assessoria de risco em circunstância alguma.
            </p>

            <h2>CLÁUSULA 13ª – MODIFICAÇÕES</h2>
            <p>
              <strong>13.1.</strong> A SIMPLES DENTAL poderá, a qualquer tempo e independente de
              notificação prévia, alterar parte ou totalmente estes Termos de Uso, ao seu exclusivo
              critério, incluindo sem limitar suas regras de participação, regras de pagamento,
              regras de acesso e cadastro, bem como as condições, serviços atrelados e custos
              relacionados a cada um dos Planos oferecidos aos Usuários, podendo inclusive
              descontinuar modalidades de Planos, sendo ofertado aos clientes migração. O uso
              contínuo da PLATAFORMA SIMPLES DENTAL após as modificações implicará no aceite tácito
              de dos Termos de Uso alterados pela SIMPLES DENTAL.
            </p>

            <h2>CLÁUSULA 14ª – DO NÍVEL DE SERVIÇO</h2>
            <p>
              <strong>14.1.</strong> A SIMPLES DENTAL empreenderá esforços comercialmente razoáveis
              para tornar a Plataforma disponível, no mínimo, 99,6% (noventa e nove vírgula seis por
              cento) durante cada Ano de Serviços.
            </p>
            <p>
              <strong>14.1.1.</strong> Por Ano de Serviço entenda-se os 365 dias precedentes à data
              de uma reivindicação relacionada ao nível de serviço. Se o Usuário estiver se
              utilizando da SIMPLES DENTAL durante período inferior a 365 dias, o Ano de Serviço que
              lhe corresponde será, ainda assim, considerado como os 365 dias precedentes; no
              entanto, os dias anteriores a seu uso dos serviços serão considerados como de 100% de
              disponibilidade. Os períodos de inatividade operacional que ocorrerem antes de uma
              reivindicação bem sucedida de Crédito de Serviço não poderão ser usados para efeito de
              reivindicações futuras.
            </p>
            <p>
              <strong>14.2.</strong> O Compromisso de nível de serviço não se aplica às
              circunstâncias de indisponibilidade que resultem (i) de uma interrupção do
              fornecimento de energia elétrica ou paradas emergenciais não superiores a 2 (duas)
              horas ou que ocorram no período das 24:00h até às 6:00h (horário de Brasília); (ii)
              forem causadas por fatores que fujam ao cabível controle da SIMPLES DENTAL, inclusive
              casos de força maior ou de acesso à Internet e problemas correlatos; (iii) resultem de
              quaisquer atos ou omissões do Usuário ou de terceiros; (iv) resultem do equipamento,
              software ou outras tecnologias que o Usuário usar e/ou do equipamento que impeçam o
              acesso regular à SIMPLES DENTAL; (v) resultem de falhas de instâncias individuais não
              atribuíveis à indisponibilidade do Usuário; (vi) resultem de alterações realizadas na
              forma de acesso a informações financeiras e/ou de Conta do Usuário pelas instituições
              financeiras; (vii) resultem de práticas de gerenciamento da rede que possam afetar sua
              qualidade.
            </p>

            <h2>CLÁUSULA 15ª – DAS DISPOSIÇÕES GERAIS</h2>
            <p>
              <strong>15.1.</strong> O Usuário autoriza a SIMPLES DENTAL a divulgar a contratação
              dos serviços da SIMPLES DENTAL em caráter publicitário, podendo mencionar ou divulgar
              ao mercado que o Usuário é usuário da SIMPLES DENTAL.
            </p>
            <p>
              <strong>15.2.</strong> Estes Termos de Uso, em conjunto com seus anexos e com a
              Política de Privacidade representam o integral entendimento entre as Partes e
              substitui toda e qualquer proposta ou documento anterior que tenha sido presentado
              pela SIMPLES DENTAL, sobre os quais tem preferência.
            </p>
            <p>
              <strong>15.3.</strong> As Partes se comprometem a zelar pelo bom desempenho da relação
              comercial, pela função social e pelo equilíbrio econômico-financeiro destes Termos de
              Uso, cooperando entre si para o cumprimento de suas obrigações, ficando ajustado que
              os casos omissos serão resolvidos por entendimento direto entre as Partes.
            </p>
            <p>
              <strong>15.4.</strong> A nulidade ou inexequibilidade de qualquer cláusula destes
              Termos de Uso não deverá afetar as demais cláusulas destes Termos de Uso, que
              permanecerá válido e em pleno vigor em relação a todas as demais cláusulas e
              condições.
            </p>
            <p>
              <strong>15.5.</strong> O não exercício ou o atraso no exercício, por qualquer das
              Partes, dos direitos a essas respectivamente conferidos conforme previsto nestes
              Termos de Uso, não será interpretado como renúncia em relação a tal direito. Toda e
              qualquer renúncia aos direitos estabelecidos nestes Termos de Uso somente será válida
              quando entregue por escrito e assinada pela Parte renunciante.
            </p>
            <p>
              <strong>15.6.</strong> Cada Parte será exclusiva e individualmente responsável, de
              acordo com a legislação aplicável, por quaisquer tributos devidos em decorrência das
              obrigações previstas nestes Termos de Uso.
            </p>
            <p>
              <strong>15.7.</strong> O Usuário declara, sob as penas da lei, que o Aceite destes
              Termos de Uso é realizado por pessoas que são seus procuradores/representantes legais,
              devidamente qualificados e constituídos na forma de seus respectivos contratos ou
              estatutos sociais, com poderes para assumir as obrigações ora pactuadas.
            </p>
            <p>
              <strong>15.8.</strong> Estes Termos de Uso não geram nenhuma relação de sociedade, de
              mandato ou relação de trabalho entre o Usuário e a SIMPLES DENTAL, nem qualquer
              compromisso de lucratividade e/ou de resultado.
            </p>
            <p>
              <strong>15.9.</strong> As obrigações, direitos e deveres assumidos nestes Termos de
              Uso não poderão ser cedidos pelo Usuário sem o prévio e expresso consentimento por
              escrito da SIMPLES DENTAL.
            </p>
            <p>
              <strong>15.10.</strong> As obrigações, direitos e deveres assumidos nestes Termos de
              Uso poderão ser cedidos pela SIMPLES DENTAL a qualquer outra empresa a seu critério.
            </p>

            <h2>CLÁUSULA 16ª - LEI E FORO APLICÁVEIS</h2>
            <p>
              <strong>16.1.</strong> Estes Termos de Uso serão interpretados exclusivamente segundo
              as leis do Brasil.
            </p>
            <p>
              <strong>16.2.</strong> As partes elegem o Foro da Comarca de Florianópolis, Estado de
              Santa Catarina, como o único competente para dirimir qualquer litígio resultante
              destes Termos de Uso ou da Política de Privacidade.
            </p>

            <h2>PLANOS OFERECIDOS:</h2>
            <p>
              <strong>17.1.</strong> A utilização do Plano Freemium incluirá todos os serviços que
              não exijam o pagamento de tarifas adicionais, conforme disponibilizados na Plataforma.
              A SIMPLES DENTAL reserva-se o direito de alterar unilateralmente a quantidade ou
              diversidade de serviços disponibilizados no Plano Freemium, bem como de cancelar a
              oferta do Plano Freemium (ou alterar os requisitos para que clientes possam usufruir
              do Plano Freemium), independentemente de notificação ou autorização do Usuário, sem
              qualquer incidência de ônus ou penalidade.
            </p>
            <p>
              <strong>17.2.</strong> O Usuário concorda que a SIMPLES DENTAL apenas oferece ao
              Usuário mecanismo auxiliar de controle, agendamento e comunicação com seus Pacientes,
              dessa forma será responsabilidade exclusiva do Usuário verificar: (i) se toda a
              informação inserida está correta; e se (ii) o conteúdo das comunicações enviadas aos
              Pacientes, independentemente da forma empregada, está adequado às leis e regulamentos
              aplicáveis.
            </p>
            <p>
              <strong>17.3.</strong> A SIMPLES DENTAL não se responsabiliza por danos ou prejuízos
              ocasionados ao Usuário que sejam decorrentes da interrupção de acesso, por qualquer
              motivo, à Plataforma, sendo responsabilidade do Usuário garantir um meio alternativo
              de contato com o Paciente. Sem limitar o disposto acima, a SIMPLES DENTAL também não
              será responsável em situações nas quais houve falha nos sistemas de transmissão ou de
              roteamento de dados via internet e/ou falhas por interferências ou aplicativos de
              terceiros (incluindo, mas sem limitação a atuação de hackers, falha de provedor de
              conexão, falha do gateway de pagamento ou de instituição bancária, falha nos serviços
              de armazenamento do provedor terceiro usado para hospedagem, entre outros do tipo).
              Durante o uso da Plataforma a SIMPLES DENTAL envidará seus melhores esforços para
              tentar informar imediatamente ao Usuário se houver qualquer problema de conexão ou
              outra falha que de qualquer forma impacte os Usuários da Plataforma.
            </p>
            <p>
              <strong>17.4.</strong> Obriga-se o Usuário a manter pessoal treinado para a operação
              da Plataforma e prover em tempo hábil, sempre que ocorrerem quaisquer problemas com a
              Plataforma, toda a documentação, relatórios e demais informações que relatem as
              circunstâncias em que os problemas ocorreram, objetivando facilitar e agilizar os
              trabalhos, bem como manter, às suas expensas, linha de telecomunicação, modem,
              software de comunicação, endereço de correio eletrônico atualizado e outros recursos
              necessários à comunicação com a Plataforma SIMPLES DENTAL.
            </p>

            <h2 id="plano-freemium">Plano Freemium:</h2>
            <p>
              Plano Freemium: significa plano gratuito ofertado exclusivamente a Clientes Dental
              Cremer e Clientes Dental Speed ativos e adimplentes, conforme regras específicas, que
              efetuaram cadastros junto à Plataforma SIMPLES DENTAL até a data de 17 de janeiro de
              2018 e que oferece acesso a certos serviços sem custo ao Usuário e possibilita a
              contratação paga de Serviços Adicionais (exclusivamente SMS).
            </p>
            <hr />
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>
                          <i>Serviços</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Descrição</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Valor</i>
                        </b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Agenda</p>
                    </td>
                    <td>
                      <p>
                        Módulo que possibilita agendamentos de consultas, alteração de status,
                        marcação de alerta de retorno.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Financeiro</p>
                    </td>
                    <td>
                      <p>Módulo para controle de contas a pagar e a receber</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Ficha do Paciente</p>
                    </td>
                    <td>
                      <p>
                        Elaboração de orçamentos, descrição de tratamentos, anamnese, inclusão de
                        imagens, atestados, receituário e débitos.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Ajustes</p>
                    </td>
                    <td>
                      <p>
                        Módulo que permite cadastro de equipe, caixa, anamnese e eventuais ajustes
                        necessários.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Inteligência</p>
                    </td>
                    <td>
                      <p>
                        Módulo com indicadores de performance com porcentagem de orçamentos
                        aprovados, ticket médio e lucratividade.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Simples Mobile</p>
                    </td>
                    <td>
                      <p>Aplicativo que permite marcar consultas, cadastrar pacientes;</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Confirmação de* consulta</p>
                    </td>
                    <td>
                      <p>Automação de confirmação de consultas por SMS**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Campanhas*</p>
                    </td>
                    <td>
                      <p>Automação de campanhas de Marketing enviadas por SMS**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p></p>
            <p></p>
            <h2 id="plano-agenda-">
              <b>Plano Agenda</b>
            </h2>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>
                          <i>Serviços</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Descrição</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Valor</i>
                        </b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Agenda</p>
                    </td>
                    <td>
                      <p>
                        Módulo que possibilita agendamentos de consultas, alteração de status,
                        marcação de alerta de retorno.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Ficha do Paciente</p>
                    </td>
                    <td>
                      <p>
                        Elaboração de orçamentos, descrição de tratamentos, anamnese, inclusão de
                        imagens, atestados, receituário e débitos.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Ajustes</p>
                    </td>
                    <td>
                      <p>
                        Módulo que permite cadastro de equipe, caixa, anamnese e eventuais ajustes
                        necessários.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Simples Mobile</p>
                    </td>
                    <td>
                      <p>Aplicativo que permite marcar consultas, cadastrar pacientes;</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Confirmação de consulta*</p>
                    </td>
                    <td>
                      <p>Automação de confirmação de consultas por WhatsApp e SMS**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Campanhas*</p>
                    </td>
                    <td>
                      <p>Automação de campanhas de Marketing enviadas por WhatsApp e SMS**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Site do Dentista</p>
                    </td>
                    <td>
                      <p>
                        Ferramenta que permite a criação, edição e publicação de uma página para
                        captação de leads interessados na clínica ou consultório.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p></p>
            <p></p>
            <h2 id="plano-gesto-">Plano Gestão</h2>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>
                          <i>Serviços</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Descrição</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Valor</i>
                        </b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Agenda</p>
                    </td>
                    <td>
                      <p>
                        Módulo que possibilita agendamentos de consultas, alteração de status,
                        marcação de alerta de retorno.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Financeiro</p>
                    </td>
                    <td>
                      <p>Módulo para controle de contas a pagar e a receber</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Ficha do Paciente</p>
                    </td>
                    <td>
                      <p>
                        Elaboração de orçamentos, descrição de tratamentos, anamnese, inclusão de
                        imagens, atestados, receituário e débitos.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Emissão de boletos e Maquininha de cartão</p>
                    </td>
                    <td>
                      <p>
                        Emissão de boletos direto pelo débito do paciente e integração com
                        maquininha de cartão da Saúde Service.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Comissionamento de profissionais</p>
                    </td>
                    <td>
                      <p>
                        Módulo que calcula a comissão de profissionais de forma automática de acordo
                        com as regras cadastradas
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Ajustes</p>
                    </td>
                    <td>
                      <p>
                        Módulo que permite cadastro de equipe, caixa, anamnese e eventuais ajustes
                        necessários.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Inteligência</p>
                    </td>
                    <td>
                      <p>
                        Módulo com indicadores de performance com porcentagem de orçamentos
                        aprovados, ticket médio, lucratividade e controle de Ortodontia.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Estoque</p>
                    </td>
                    <td>
                      <p>Controle de entrada e retirada de produtos</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Simples Mobile</p>
                    </td>
                    <td>
                      <p>
                        Aplicativo que permite marcar consultas, cadastrar pacientes, adicionar um
                        profissional, criar uma nova clínica;
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Confirmação de consulta*</p>
                    </td>
                    <td>
                      <p>Automação de confirmação de consultas por WhatsApp e SMS**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Campanhas*</p>
                    </td>
                    <td>
                      <p>Automação de campanhas de Marketing enviadas por WhatsApp e SMS**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Assinatura Eletrônica*</p>
                    </td>
                    <td>
                      <p>Automação de solicitação de Assinatura Eletrônica para seus pacientes**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Site do Dentista</p>
                    </td>
                    <td>
                      <p>
                        Ferramenta que permite a criação, edição e publicação de uma página para
                        captação de leads interessados na clínica ou consultório.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Análise de Crédito</p>
                    </td>
                    <td>
                      <p>Ferramenta para consultar a pontuação (score) de crédito do paciente**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p></p>
            <p></p>
            <h2 id="plano-venda-">Plano Vendas</h2>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>
                          <i>Serviços</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Descrição</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Valor</i>
                        </b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Agenda</p>
                    </td>
                    <td>
                      <p>
                        Módulo que possibilita agendamentos de consultas, alteração de status,
                        marcação de alerta de retorno.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Financeiro</p>
                    </td>
                    <td>
                      <p>Módulo para controle de contas a pagar e a receber</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Ficha do Paciente</p>
                    </td>
                    <td>
                      <p>
                        Elaboração de orçamentos, descrição de tratamentos, anamnese, inclusão de
                        imagens, atestados, receituário e débitos.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Emissão de boletos e Maquininha de cartão</p>
                    </td>
                    <td>
                      <p>
                        Emissão de boletos direto pelo débito do paciente e integração com
                        maquininha de cartão da Saúde Service.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Comissionamento de profissionais</p>
                    </td>
                    <td>
                      <p>
                        Módulo que calcula a comissão de profissionais de forma automática de acordo
                        com as regras cadastradas
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Ajustes</p>
                    </td>
                    <td>
                      <p>
                        Módulo que permite cadastro de equipe, caixa, anamnese e eventuais ajustes
                        necessários.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Inteligência</p>
                    </td>
                    <td>
                      <p>
                        Módulo com indicadores de performance com porcentagem de orçamentos
                        aprovados, ticket médio, lucratividade e controle de Ortodontia.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>CRM</p>
                    </td>
                    <td>
                      <p>Gerenciador de clientes integrado ao Simples Dental</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Controle de vendas</p>
                    </td>
                    <td>
                      <p>Automação de processos de controle de vendas e agendamentos</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>WhatsApp Web</p>
                    </td>
                    <td>
                      <p>
                        Acesso facilitado para entrar em contato com seu paciente pelo seu WhatsApp
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Menu Estoque</p>
                    </td>
                    <td>
                      <p>Controle de entrada e retirada de produtos</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Simples Mobile</p>
                    </td>
                    <td>
                      <p>
                        Aplicativo que permite marcar consultas, cadastrar pacientes, adicionar um
                        profissional, criar uma nova clínica;
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Confirmação de consulta*</p>
                    </td>
                    <td>
                      <p>Automação de confirmação de consultas por WhatsApp e SMS**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Campanhas*</p>
                    </td>
                    <td>
                      <p>Automação de campanhas de Marketing enviadas por WhatsApp e SMS**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Assinatura Eletrônica*</p>
                    </td>
                    <td>
                      <p>Automação de solicitação de Assinatura Eletrônica para seus pacientes**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Site do Dentista</p>
                    </td>
                    <td>
                      <p>
                        Ferramenta que permite a criação, edição e publicação de uma página para
                        captação de leads interessados na clínica ou consultório.
                      </p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Análise de Crédito</p>
                    </td>
                    <td>
                      <p>Ferramenta para consultar a pontuação (score) de crédito do paciente**</p>
                    </td>
                    <td>
                      <p>Incluso</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2 id="plano-redes-franquias-">Plano Redes & Franquias</h2>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>
                          <i>Serviços</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Descrição</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Valor</i>
                        </b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Painel Analítico para Redes e Franquias de Clínicas Odontológicas</p>
                    </td>
                    <td>
                      <p>
                        Painel com indicadores de performance de diferentes unidades de uma Rede ou
                        Franquia com dados sobre agendamentos, orçamentos, vendas, profissionais,
                        faturamento, despesas e lucratividade..
                      </p>
                    </td>
                    <td>
                      <p>Sob Consulta</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p></p>
            <p>* Para o envio de mensagem é necessário ter saldo de WhatsApp/SMS disponível</p>
            <p>
              ** Os Pacotes de SMS, WhatsApp, Assinatura Eletrônica e Análise de Crédito são
              vendidos separadamente de acordo com a tabela de Serviços Adicionais
            </p>
            <p></p>
            <p></p>
            <h2 id="servios-adicionais">Serviços Adicionais:</h2>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>
                          <i>Serviços</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Descrição</i>
                        </b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>
                          <i>Valor</i>
                        </b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote SMS 1</p>
                    </td>
                    <td>
                      <p>Pacote com 300 SMSs</p>
                    </td>
                    <td>
                      <p>R$ 90,00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote SMS 2</p>
                    </td>
                    <td>
                      <p>Pacote com 600 SMSs</p>
                    </td>
                    <td>
                      <p>R$ 150,00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote SMS 3</p>
                    </td>
                    <td>
                      <p>Pacote com 1000 SMSs</p>
                    </td>
                    <td>
                      <p>R$ 250,00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote WhatsApp 1</p>
                    </td>
                    <td>
                      <p>Pacote com 300 WPPs</p>
                    </td>
                    <td>
                      <p>R$ 105,00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote WhatsApp 2</p>
                    </td>
                    <td>
                      <p>Pacote com 600 WPPs</p>
                    </td>
                    <td>
                      <p>R$ 200,00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote WhatsApp 3</p>
                    </td>
                    <td>
                      <p>Pacote com 1000 WPPs</p>
                    </td>
                    <td>
                      <p>R$ 300,00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote Assinatura Eletrônica 1</p>
                    </td>
                    <td>
                      <p>Pacote com 250 assinaturas</p>
                    </td>
                    <td>
                      <p>R$ 99,90</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote Assinatura Eletrônica 2</p>
                    </td>
                    <td>
                      <p>Pacote com 600 assinaturas</p>
                    </td>
                    <td>
                      <p>R$ 199,90</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote Assinatura Eletrônica 3</p>
                    </td>
                    <td>
                      <p>Pacote com 1000 assinaturas</p>
                    </td>
                    <td>
                      <p>R$ 299,90</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote Análise de Crédito 1</p>
                    </td>
                    <td>
                      <p>Pacote com 25 consultas de crédito</p>
                    </td>
                    <td>
                      <p>R$ 99,00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote Análise de Crédito 2</p>
                    </td>
                    <td>
                      <p>Pacote com 50 consultas de crédito</p>
                    </td>
                    <td>
                      <p>R$ 179,00</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Pacote Análise de Crédito 3</p>
                    </td>
                    <td>
                      <p>Pacote com 100 consultas de crédito</p>
                    </td>
                    <td>
                      <p>R$ 297,90</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Migração de dados</p>
                    </td>
                    <td>
                      <p>
                        Importação dos dados cadastrais dos pacientes para a Plataforma Simples
                        Dental
                      </p>
                    </td>
                    <td>
                      <p>R$150,00/h</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Boleto*</p>
                    </td>
                    <td>
                      <p>Valor cobrado do Usuário por cada boleto recebido*</p>
                    </td>
                    <td>
                      <p>R$ 3,87</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Transferência</p>
                    </td>
                    <td>
                      <p>Valor cobrado por cada saque do valor disponível recebido de boletos</p>
                    </td>
                    <td>
                      <p>R$ 3,67</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Painel Analítico para Redes e Franquias de Clínicas Odontológicas</p>
                    </td>
                    <td>
                      <p>
                        Painel com indicadores de performance de diferentes unidades de uma Rede ou
                        Franquia com dados sobre agendamentos, orçamentos, vendas, profissionais,
                        faturamento, despesas e lucratividade.
                      </p>
                    </td>
                    <td>
                      <p>Sob Consulta</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p></p>
            <p>* Valor pode variar de acordo com multas e juros aplicados ao boleto</p>
          </article>
        </div>
      </Container>
    </Root>
  </>
);

export default Termos;
